export enum VulcanHousingTypeValues {
  Appartment = 'appartment',
  House = 'house',
}

export enum VulcanHousingAgeValues {
  LessThanTwoYears = 'less_than_2_years',
  BetweenTwoAndFifteenYears = 'between_2_and_15_years',
  MoreThanFifteenYears = 'more_than_15_years',
}

export enum VulcanAccomodationFloorsCountValues {
  OneLevelHouse = 'one_level_house',
  TwoLevelsHouse = 'two_levels_house',
  MoreThanTwoLevelsHouse = 'more_than_two_levels_house',
}

export enum VulcanExistingEnergyValues {
  Coal = 'coal',
  Electricity = 'electricity',
  HeatingOil = 'heating_oil',
  HeatPump = 'heat_pump',
  LPG = 'lpg',
  NaturalGaz = 'natural_gas',
  Wood = 'wood',
}

export enum VulcanExistingHeatingElectricType {
  ElectricBoiler = 'electric_boiler',
  ElectricHeating = 'electric_heating',
  LowEfficiencyElectricHeating = 'low_efficiency_electric_heating',
}

export enum VulcanExistingHeatingOilType {
  CondensingOilBoiler = 'condensing_oil_boiler',
  NonCondensingOilBoiler = 'non_condensing_oil_boiler',
  OilHeating = 'oil_heating',
}

export enum VulcanExistingHeatingPumpType {
  AirAirHeatPump = 'air_air_heat_pump',
  AirWaterHeatPump = 'air_water_heat_pump',
  WaterWaterHeatPump = 'water_water_heat_pump',
}

export enum VulcanExistingHeatingGasType {
  CondensingGasBoiler = 'condensing_gas_boiler',
  NonCondensingGasBoiler = 'non_condensing_gas_boiler',
  GasHeating = 'gas_heating',
  /* RadiatorGasHeating = 'radiator_gas_heating',
  StoveGasHeating = 'stove_gas_heating', */
}

export enum VulcanEnergyBalanceReasonValues {
  UpcomingHouseSale = 'upcoming_house_sale',
  EnergyBillReduction = 'energy_bill_reduction',
  Comfort = 'comfort',
  GhgReduction = 'ghg_reduction', // GES
  Curiosity = 'curiosity',
}

export enum VulcanHouseShapeValues {
  Square = 'square',
  Elongated = 'elongated',
  Complex = 'complex',
}

export enum VulcanTerracedHouseValues {
  No = 'no',
  OneSide = 'one_side',
  BothSides = 'both_sides',
}

export enum VulcanVentilationSystemValues {
  NoCmv = 'no_cmv',
  SingleFlowCmv = 'single_flow_cmv',
  DoubleFlowCmv = 'double_flow_cmv',
}

export enum VulcanUserCivilityValues {
  Mr = 'Mr',
  Mrs = 'Mrs',
}

export enum VulcanQuidEcsValues {
  Boiler = 'boiler',
  WaterHeaterMore10Years = 'water_heater_more10years',
  ThermodynamicWaterHeater = 'thermodynamic_water_heater',
  SolarWaterHeater = 'solar_water_heater',
}

export type VulcanExistingHeatingType = VulcanExistingHeatingGasType | VulcanExistingHeatingPumpType | VulcanExistingHeatingOilType;

export enum VulcanExistingHeatingType2 {
  ElectricHeating = 'electric_heating',
  WoodHeating = 'wood_heating',
}

export type VulcanExistingHeatingTypeParcoursSerenity =
  | VulcanExistingHeatingGasType
  | VulcanExistingHeatingElectricType
  | VulcanExistingHeatingOilType;

export enum VulcanExistingEnergyValuesParcoursSerenity {
  ElectricHeating = 'electric_heating',
  GasHeating = 'gas',
  OilHeating = 'heating_oil',
}

export enum VulcanAtticsType {
  HabitableAttics = 'habitable_attics',
  UnoccupiedAttics = 'unoccupied_attics',
}

export enum VulcanAirWaterHeatPumpSanitaryWater {
  Heating = 'heating',
  HeatingAndWater = 'heating_and_water',
}

export enum VulcanInsulatedWallsValues {
  InteriorInsulation = 'interior_insulation',
  ExteriorInsulation = 'exterior_insulation',
  No = 'non',
  DoesntKnow = 'nsp',
}

export enum VulcanWorkTypeValues {
  Aerovoltaic = 'aerovoltaic',
  AirAirHeatPump = 'air_air_heat_pump',
  AirWaterHeatPump = 'air_water_heat_pump',
  AtticsInsulation = 'attics_insulation',
  AuditEnergetique = 'energy_audit',
  CombinedSolarSystem = 'combined_solar_system',
  CondensingGasBoiler = 'condensing_gas_boiler',
  CondensingOilBoiler = 'condensing_oil_boiler',
  DoubleFlowCmv = 'double_flow_cmv',
  DoubleGlazing = 'double_glazing',
  ExternalWallInsulation = 'external_wall_insulation',
  FireplaceInsert = 'fireplace_insert',
  FloorInsulation = 'floor_insulation',
  GeothermalHeatPump = 'geothermal_heat_pump',
  HybridHeatPump = 'hybrid_heat_pump',
  InternalWallInsulation = 'internal_wall_insulation',
  PhotovoltaicPanel = 'photovoltaic_panel',
  RoofTerraceInsulation = 'roof_terrace_insulation',
  SolarWaterHeater = 'solar_water_heater',
  StorageHeater = 'storage_heater',
  ThermodynamicWaterHeat = 'thermodynamic_water_heater',
  WallInsulation = 'wall_insulation',
  WoodBoiler = 'wood_boiler',
  WoodStove = 'wood_stove',
}

export enum VulcanDpeAndGesScoreValues {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}

export enum VulcanInsulationTypeValues {
  Internal = 'internal',
  External = 'external',
}

export enum VulcanWoodStoveWorkProjectHeatingCombustibleValues {
  Log = 'log', // Bûches
  Pellet = 'pellet', // Granulés
  Dual = 'dual', // Mixte
}

export enum VulcanPrecarityLevelValues {
  VeryModest = 'very_modest',
  Modest = 'modest',
  Standard = 'standard',
}

export enum VulcanPrecarityAnahLevelValues {
  VeryModest = 'very_modest',
  Modest = 'modest',
  Intermediate = 'intermediate',
  HighIncome = 'high_income',
}

export type VulcanTyped = {
  [VulcanKeys.UserFirstName]: string;
  [VulcanKeys.UserLastName]: string;
  [VulcanKeys.UserCivility]: VulcanUserCivilityValues;
  [VulcanKeys.UserEmail]: string;
  [VulcanKeys.PhoneNumber]: string;
  [VulcanKeys.Uid]: string;
  [VulcanKeys.HousingType]: VulcanHousingTypeValues;
  [VulcanKeys.AccommodationZipCode]?: string;
  [VulcanKeys.YearOfConstruction]?: string;
  [VulcanKeys.AccomodationFloorsCount]?: VulcanAccomodationFloorsCountValues;
  [VulcanKeys.AtticsInsulationExistingInsulation]?: boolean;
  [VulcanKeys.FloorInsulationExistingInsulationAge]?: boolean;
  [VulcanKeys.InsulatedWall]?: VulcanInsulatedWallsValues;
  [VulcanKeys.ExistingGlazingMaterialType]?: boolean;
  [VulcanKeys.ExistingHeatingType]?: VulcanExistingHeatingGasType | VulcanExistingHeatingPumpType | VulcanExistingHeatingOilType;
  [VulcanKeys.ExistingHeating]?: 'true' | 'false';
  [VulcanKeys.ExistingHeatingType2]?: VulcanExistingHeatingType2 | null;
  [VulcanKeys.ExistingEnergy]?: VulcanExistingEnergyValues;
  [VulcanKeys.SurfaceArea]?: number;
  [VulcanKeys.EnergyBalanceReason]?: VulcanEnergyBalanceReasonValues;
  [VulcanKeys.HouseShape]?: VulcanHouseShapeValues;
  [VulcanKeys.TerracedHouse]?: VulcanTerracedHouseValues;
  [VulcanKeys.AirConditioningSystem]?: boolean;
  [VulcanKeys.VentilationSystem]?: VulcanVentilationSystemValues;
  [VulcanKeys.QuidEcs]?: VulcanQuidEcsValues;
  [VulcanKeys.UtmFunnel]?: string;
  [VulcanKeys.InscriptionNewsletter]?: boolean;
  [VulcanKeys.WorkType]?: VulcanWorkTypeValues[];
  [VulcanKeys.DpeSimulationScore]?: VulcanDpeAndGesScoreValues;
  [VulcanKeys.GesScore]?: VulcanDpeAndGesScoreValues;
  [VulcanKeys.GlobalScore]?: VulcanDpeAndGesScoreValues;
  [VulcanKeys.WallInsulationType]?: VulcanInsulationTypeValues;
  [VulcanKeys.WoodStoveWorkProjectHeatingCombustible]?: VulcanWoodStoveWorkProjectHeatingCombustibleValues;
  [VulcanKeys.Partner]?: string;
  [VulcanKeys.PrecarityLevel]?: VulcanPrecarityLevelValues;
  [VulcanKeys.PrecarityAnahLevel]?: VulcanPrecarityAnahLevelValues;
};

export type VulcanModel =
  | {
      [key in VulcanKeys]?: unknown;
    }
  | VulcanTyped;

export enum VulcanKeys {
  AccommodationBasement = 'accommodation_basement',
  AccommodationCrawlSpaceHeight = 'accommodation_crawlspace_height',
  AccomodationFloorsCount = 'accommodation_floors_count',
  AccommodationOlderThanTwoYears = 'accommodation_older_than_two_years',
  AccommodationRoofType = 'accommodation_roof_type',
  AccommodationZipCode = 'accommodation_zip_code',
  AccommodationCity = 'accommodation_city',
  AccommodationAddress = 'accommodation_address',
  AccommodationLatitude = 'accommodation_latitude',
  AccommodationLongitude = 'accommodation_longitude',
  AirConditioningSystem = 'air_conditioning_system',
  AirWaterHeatPumpType = 'air_water_heat_pump.workproject_heat_pump_type',
  AtticInsulationSurface = 'attics_insulation.workproject_insulation_surface',
  AtticsInsulationExistingInsulation = 'attics_insulation.existing_insulation',
  AtticsType = 'attics_type',
  Address = 'address',
  CallingDate = 'calling_date',
  ReasonAppointment = 'motif_rdv',
  CodeParrain = 'sponsorUid',
  DoubleGlazingWindowsCount = 'double_glazing.workproject_windows_count',
  DpeScore = 'reported_dpe_score',
  EnergyBalanceReason = 'energy_balance_reason',
  ExistingEnergy = 'existing_energy',
  ExistingHeating = 'existing_heating',
  ExistingHeatingType = 'existing_heating_type',
  ExistingHeatingType2 = 'existing_heating_type_2', // secondary source of heating
  ExistingWaterHeaterType = 'existing_water_heater_type',
  FloorIncsulationSurface = 'floor_insulation.workproject_insulation_surface',
  FloorInsulationExistingInsulationAge = 'floor_insulation.existing_insulation_age',
  GeoThermalHeatPumpType = 'geothermal_heat_pump.workproject_heat_pump_type',
  HousingAge = 'housingAge',
  HouseOccupants = 'houseOccupants',
  HouseShape = 'house_shape',
  HousingType = 'housingType',
  HybridHeatPumpType = 'hybrid_heat_pump.workproject_heat_pump_type',
  OwnerStatus = 'ownerStatus',
  PhoneNumber = 'user_phone1',
  PhoneNumber2 = 'user_phone2',
  PrecarityLevel = 'precarity.level',
  PrecarityAnahLevel = 'precariousness_maprimerenov',
  ProjectState = 'project_progress',
  QuidEcs = 'quid_ecs',
  Timing = 'wished_workproject_deadline',
  ProjectDescription = 'description_projet',
  RoofTerraceInsulationSurface = 'roof_terrace_insulation.workproject_insulation_surface',
  SponsorCode = 'sponsor_code',
  SolarWaterHeaterPanelSurface = 'solar_water_heater.workproject_panel_surface',
  StorageHeaterRadiatorsCount = 'storage_heater.workproject_radiators_count',
  SurfaceArea = 'surfaceArea',
  TerracedHouse = 'terraced_house',
  Uid = 'uid',
  UserCity = 'city',
  UserCivility = 'user_civility',
  UserEmail = 'user_email',
  UserFirstName = 'user_firstName',
  UserLastName = 'user_lastName',
  UserZipcode = 'zipCode',
  UtmSource = 'utm.source',
  UtmCampaign = 'utm.campaign',
  UtmMedium = 'utm.medium',
  UtmContent = 'utm.content',
  UtmTerm = 'utm.term',
  UtmFunnel = 'utm.funnel',
  UtmReferrer = 'utm.referrer',
  UtmGclid = 'utm.gclid',
  VentilationSystem = 'ventilation_system',
  YearOfConstruction = 'yearOfConstruction',
  InsulatedWall = 'insulated_walls',
  InsulationSurface = 'wall_insulation.workproject_insulation_surface',
  WallInsulationType = 'wall_insulation.workproject_insulation_type',
  WoodStoveWorkProjectHeatingCombustible = 'wood_stove.workproject_heating_combustible',
  WorkType = 'works_type',
  WorksReason = 'workproject_reason',
  DpeSimulationScore = 'see_energy_score',
  GesScore = 'see_ges_score',
  GlobalScore = 'see_dpe_score',
  // ## Cost ##
  AirAirTotalCosts = 'air_air_heat_pump.workproject_total_cost',
  DoubleGlazingTotalCosts = 'double_glazing.workproject_total_cost',
  FloorInsulationTotalCosts = 'floor_insulation.workproject_total_cost',
  GeothermalHeatPumpTotalCosts = 'geothermal_heat_pump.workproject_total_cost',
  HabitableAtticsInsulationTotalCosts = 'habitable_attics_insulation.workproject_total_cost',
  InternalWallInsulationTotalCosts = 'internal_wall_insulation.workproject_total_cost',
  ExternalWallInsulationTotalCosts = 'external_wall_insulation.workproject_total_cost',
  RoofTerraceTotalCosts = 'roof_terrace_insulation.workproject_total_cost',
  SolarWaterHeaterTotalCosts = 'solar_water_heater.workproject_total_cost',
  StorageHeaterTotalCosts = 'storage_heater.workproject_total_cost',
  UnoccupiedAtticsInsulationTotalCosts = 'unoccupied_attics_insulation.workproject_total_cost',
  HybridHeatPumpTotalCosts = 'hybrid_heat_pump.workproject_total_cost',
  AerovoltaicTotalCosts = 'aerovoltaic.workproject_total_cost',
  AirWaterHeatPumpTotalCosts = 'air_water_heat_pump.workproject_total_cost',
  CombinedSolarSystemTotalCosts = 'combined_solar_system.workproject_total_cost',
  CondensingGasBoilerTotalCosts = 'condensing_gas_boiler.workproject_total_cost',
  CondensingFuelBoilerTotalCosts = 'condensing_oil_boiler.workproject_total_cost',
  DoubleFlowCMVTotalCosts = 'double_flow_cmv.workproject_total_cost',
  FireplaceInsertTotalCosts = 'fireplace_insert.workproject_total_cost',
  PhotovoltaicPanelTotalCosts = 'photovoltaic_panel.workproject_total_cost',
  ThermodynamicWaterHeaterTotalCosts = 'thermodynamic_water_heater.workproject_total_cost',
  WoodPelletBoilerTotalCosts = 'wood_pellet_boiler.workproject_total_cost',
  WoodStoveTotalCosts = 'wood_stove.workproject_total_cost',

  // # to use #
  // # Helps and Subsidy #
  // ## params ##
  ExistingGlazingMaterialType = 'existing_glazing_material', // of type ExistingGlazingMaterial
  // ## Work params ##
  AerovoltaicPanelSurface = 'aerovoltaic_panel.workproject_panel_surface',
  CombinedSolarSystemPanelSurface = 'combined_solar_system.workproject_panel_surface',
  UserGotAnahAgilite = 'workproject_user_got_anah_agilite',
  UserGotCITE = 'workproject_user_got_cite',
  UserGotEcorPTZ = 'workproject_user_got_eco_ptz',

  // # heated surface PAC #
  AirAirHeatPumpWorkProjectHeatedSurface = 'air_air_heat_pump.workproject_heated_surface',
  AirWaterHeatPumpWorkProjectHeatedSurface = 'air_water_heat_pump.workproject_heated_surface',
  GeothermalHeatPumpWorkProjectHeatedSurface = 'geothermal_heat_pump.workproject_heated_surface',
  HybridHeatPumpWorkProjectHeatedSurface = 'hybrid_heat_pump.workproject_heated_surface',

  InscriptionNewsletter = 'inscription_newsletter',

  // # ga keys #
  GaClientId = 'ga_client_id',

  // ## Solar ##
  AccommodationRoofDirection = 'accommodation_roof_direction',
  AccommodationRoofSunnySurface = 'accommodation_roof_sunny_surface',
  AccommodationRoofInclination = 'accommodation_roof_inclination',
  AccommodationRoofMaterial = 'accommodation_roof_material',
  HeatingBillAmountByYear = 'heating_bill_amount_by_year',
  SolarPanelPower = 'solar_panel_power',

  // ## Partner ##
  Partner = 'partner',
}
